import TestTrack from "test_track_js_client";

(function () {
  initializeAnalytics();

  function initializeAnalytics() {
    deleteCorruptedCookie();
    initializeTestTrack();
    initializeClickEventListener();
  }

  function initializeTestTrack() {
    TestTrack.initialize({
      analytics: {
        trackAssignment: function (visitorId, assignment, callback) {
          window.analytics.track(
            "SplitAssigned",
            {
              splitName: assignment.getSplitName(),
              splitVariant: assignment.getVariant(),
              splitContext: assignment.getContext(),
            },
            {},
            function () {
              callback(true);
            }
          );
        },
      },

      onVisitorLoaded: function (visitor) {
        window.analytics.identify(visitor.getId());
      },
    });
  }

  /**
      Christopher Zega
      A bug was introduced that created an invalid cookie. The invalid cookie breaks
      the functionality of $.cookies(), which is used in TestTrack.initialize(). This will
      function will remove the invalid cookie.
    */
  function deleteCorruptedCookie() {
    document.cookie =
      "mp_<%=;path=/;domain=.bettermentadvisorsolutions.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  function initializeClickEventListener() {
    $(document).on("click", "[data-track-event]", function (e) {
      var data = $(e.currentTarget).data();
      if (!data) {
        return;
      }

      handleSegmentEvent(data);
    });
  }

  function handleSegmentEvent(data) {
    var eventProps = getEventProperties(data);
    if (eventProps) {
      window.analytics.track(eventProps.eventName, eventProps.props);
    }
  }

  function getEventProperties(data) {
    var eventName = data.trackEvent,
      props = {
        name: data.trackName,
        flow: data.trackFlow,
        module: data.trackModule,
        application: "BettermentForAdvisors",
        client: "Web",
      };

    if (
      (eventName === "ElementClicked" || eventName === "ElementViewed") &&
      props.application &&
      props.name &&
      props.client
    ) {
      return {
        eventName: eventName,
        props: props,
      };
    }
  }
})();
